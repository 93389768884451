<template>
  <div
    class="card flex flex-col bg-white cursor-pointer rounded-l-lg rounded-t-lg transition transition-border ease-in-out"
    :class="{ hover, [borderColor]: hover }"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @click="router.push(category.link)"
  >
    <div class="flex-none flex rounded-t-lg" :class="backgroundLightColor">
      <div
        class="flex-none inline-flex justify-center items-center w-16 min-h-16 mr-5 rounded-t-lg rounded-br-lg transition duration-200 ease-in-out"
        :class="backgroundColor"
      >
        <img v-if="category.icon" :src="category.icon" :alt="category.title" class="w-[35px] h-[35px]" />
      </div>
      <h3 class="flex-1 text-sm mr-5 my-auto" :class="hover ? `text-${backgroundColor}`.replace('bg-', '') : ''">
        {{ category.title }}
      </h3>
    </div>
    <picture v-if="category.image.src" class="flex-none block overflow-hidden">
      <source
        v-for="(source, index) in category.image.sources"
        :key="`${source.srcset}-${index}`"
        :type="source.type"
        :srcset="source.srcset"
      />
      <nuxt-img
        :src="category.image.src"
        :srcset="category.image.srcset"
        :alt="category.title"
        width="270"
        fit="cover"
        height="160"
      ></nuxt-img>
    </picture>
    <div
      class="flex-1 flex flex-col justify-between rounded-bl-lg border border-transparent border-t-0 card-content"
      :class="{ '!border-transparent': hover, hover, '!border-gray-600': !hover }"
    >
      <slot name="description" :category="category">
        <p class="w-full px-5 pt-4" :class="{ 'text-sm': textSm }">{{ category[descriptionKey] }}</p>
      </slot>
      <div class="flex w-full justify-end mt-2 h-11.5 self-end">
        <nuxt-link
          class="flex justify-end items-center text-sm py-3.5 mr-4"
          :class="hover ? 'text-black' : ' text-gray-700'"
          :to="category.link"
        >
          {{ t("read-more") }}
        </nuxt-link>
        <button-basic-link
          class="w-11.5 self-end transition duration-200 -mb-px -mr-px"
          :class="hover ? backgroundColor : 'bg-black'"
          :to="category.link"
          :title="t('read-more')"
        >
          <icon-plus class="text-white fill-current w-10 h-10" :font-controlled="false" />
        </button-basic-link>
      </div>
    </div>
  </div>
</template>

<script>
import IconPlus from "~/assets/svg/plus.svg";
import ButtonBasicLink from "~/components/Buttons/ButtonBasicLink.vue";

export default {
  name: "CardCategory",
  components: {
    ButtonBasicLink,
    IconPlus,
  },
  setup() {
    const { t } = useI18n({
      useScope: "local",
    });
    const router = useRouter();
    return { t, router };
  },
  props: {
    backgroundLightColor: {
      type: String,
      required: true,
    },
    borderColor: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    textSm: {
      type: Boolean,
      required: false,
      default: false,
    },
    descriptionKey: {
      type: String,
      required: false,
      default: "longTitle",
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<i18n lang="json">
{
  "fr": {
    "read-more": "Lire plus"
  },
  "en": {
    "read-more": "Read more"
  }
}
</i18n>

<style lang="scss" scoped>
.card {
  width: 270px;
  min-width: 270px;
  position: relative;

  &::before {
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }

  &.hover {
    &.border-green {
      &::before {
        border: 1px solid #009a4e;
      }
    }

    &.border-orange {
      &::before {
        border: 1px solid #e67817;
      }
    }

    &.border-dark-blue {
      &::before {
        border: 1px solid #085da9;
      }
    }
  }

  .card-content {
    position: relative;
  }
}
</style>
